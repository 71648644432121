import React, { useRef, useState, useEffect } from 'react';
import {
  Alert,
  Container,
  Spinner,
  Button,
  Form,
  InputGroup,
  FormControl,
  Overlay,
  Popover,
  Row,
  Col,
  Modal,
} from 'react-bootstrap';
import styles from './styles.module.css';
import { getLocaleWiseLabel as t } from '../../../lib/localeUtils';
import { useForm } from 'react-hook-form';
import * as firebase from '../../../lib/firebase';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import * as SystemActions from '../../../redux/store/system/actions';
import { RootState } from '../../../redux/store';
import { SystemActionTypes } from '../../../redux/store/system/types';
import axios from 'axios';
import clientConfig from '../../../../client-config';
import rtl from '../rtl';
import { navigate } from 'gatsby';
import hebrewTexts from '../commonHebrewTexts';

const picture = require('./assets/picture.png');

export default function LoginPage(props: any) {
  const { loggedIn, userName } = useSelector((state: RootState) => {
    try {
      return {
        loggedIn: state.system.loggedIn,
        userName: state.system.userName,
        currentPage: state.system.currentPage,
        idNumber: state.system.idNumber,
        companyName: state.system.companyName,
        uid: state.system.uid,
        businessId: state.system.businessId,
      };
    } catch (error) {
      return { loggedIn: '', userName: '', currentPage: '' };
    }
  });
  const { register, handleSubmit } = useForm<{ email: string; password: string }>();
  const [errorMsg, showErrorMsg] = React.useState('');
  const [show, setShow] = React.useState(false);
  const target = useRef(null);
  useEffect(() => {
    isLoggedIn();
  });
  const dispatch = useDispatch<Dispatch<SystemActionTypes>>();
  const isLoggedIn = async () => {
    const isLoggedIn = firebase.isLoggedIn();
    return isLoggedIn;
  };
  const onSubmit = async (data: { email: string; password: string }) => {
    firebase
      .signIn(data.email, data.password)
      .then((authUser) => {
        console.log('authuser ', authUser);
        firebase.onAuthStateChange(isLoggedIn);
        let uid = authUser.user ? authUser.user.uid : '';
        axios
          .post(`${clientConfig.backend}/api/v1/Login`, { uid })
          .then((res) => {
            console.log('user login response ', res);

            let systemData = {
              loggedIn: true,
              userName: res.data.user.firstName + ' ' + res.data.user.lastName,
              currentPage: res.data.user.currentPage,
              idNumber: res.data.user.idNumber,
              companyName: res.data.user.businessUserRelation[0].business.companyName,
              businessId: res.data.user.businessUserRelation[0].business.id,
              uid: uid,
              registrarCompaniesId: res.data.user.businessUserRelation[0].business.registeredCompanyId,
            };
            localStorage.setItem('userDetails', JSON.stringify(systemData));
            localStorage.setItem(
              'loanDetails',
              JSON.stringify({
                registeredCompanyId: res.data.user.businessUserRelation[0].business.registeredCompanyId,
                companyName: res.data.user.businessUserRelation[0].business.companyName,
              })
            );
            dispatch(SystemActions.updateSession(systemData));
            dispatch({ type: 'UPDATE_SESSION', payload: systemData });
          })
          .catch((error) => {
            setShow(!show);
            showErrorMsg(error.message);
          });
      })
      .catch((error) => {
        // alert(error.message);
        // if (error.code == "auth/user-not-found") {
        setShow(!show);
        showErrorMsg(error.message);
        // }
      });
  };

  const onLogout = () => {
    try {
      firebase.logout();
      localStorage.clear();
      let systemData = {
        loggedIn: false,
        userName: '',
        currentPage: '/LoginPage',
        uid: '',
        idNumber: 0,
        companyName: '',
        businessId: 0,
      };
      dispatch(SystemActions.updateSession(systemData));
      dispatch({ type: 'UPDATE_SESSION', payload: systemData });
    } catch (error) {
      setShow(!show);
      showErrorMsg(error.message);
    }
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleClick = () => {
    navigate('/LoanPage');
  };
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body style={{ fontSize: '100%' }} className='text-right'>
          <br />
          <h6 className='text-right'>{t(props.labels, 'ErrorMessage', 'heb')}</h6>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {hebrewTexts.close}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className={styles.pageTop}>
        <img src={picture} className={styles.pageTopImage} />
        <Container>
          <Row className='justify-content-end'>
            <Col className={styles.pageTopCol}>
              <h1 className={styles.pageTopTitle}>{t(props.labels, 'login_page_top_title1', 'heb')}</h1>
              <p className={styles.pageTopTitle}>{t(props.labels, 'Landing_page_top_title2', 'heb')}</p>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className={styles.container}>
        {loggedIn ? (
          <Form className={styles.form} onSubmit={handleSubmit(onLogout)}>
            <button className={styles.button} type='submit'>
              {hebrewTexts.logout}
            </button>
          </Form>
        ) : (
          <Form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <Row className={styles.label}>{t(props.labels, 'login_email_text', 'heb')}</Row>
            <Row>
              <FormControl
                style={{ direction: 'rtl' }}
                aria-describedby='basic-addon1'
                className={styles.input}
                name='email'
                type='email'
                ref={register}
              />
            </Row>
            <Row className={styles.label}>{t(props.labels, 'login_password_text', 'heb')}</Row>
            <Row>
              <FormControl
                name='password'
                style={{ direction: 'rtl' }}
                type='password'
                className={styles.input}
                ref={register}
              />
            </Row>
            <Row>
              <button type='submit' className={styles.button}>
                {t(props.labels, 'Login_button_text', 'heb')}
              </button>
            </Row>
            <Row className={styles.noLogin}>
              <Col>{t(props.labels, 'Login_no_account_frame_text', 'heb')}</Col>
            </Row>
            <Row>
              <button type='button' className={styles.button} onClick={handleClick}>
                {t(props.labels, 'Ask_finance_black_button_text', 'heb')}
              </button>
            </Row>
          </Form>
        )}
      </Container>
    </div>
  );
}
